import { createSelector, lruMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

export const getScrollState = createSelector(
  [state => state],
  state => ({
    searchAndBrowse: state.searchAndBrowse,
    entities: state.entities,
    taxonomy: state.taxonomy,
    content: state.content,
    page: state.page,
  }),
  {
    memoize: lruMemoize,
    memoizeOptions: {
      equalityCheck: isEqual,
      maxSize: 10,
      resultEqualityCheck: isEqual,
    },
  },
);

export const getScrolledLineNumber = (state: WtrState) => state.scrollSessionStorage?.lineNumber;
