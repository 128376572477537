import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { LazyIcon } from 'components/LazyIcon/LazyIcon';
import styles from './MultibuyNudge.scss';

const MultibuyNudge = ({ multibuyPromotionPath, variantCopy }) => {
  return (
    <Link className={classnames(styles.wrapper)} to={multibuyPromotionPath}>
      <LazyIcon name="Pound" className={styles.icon} />
      <Typography styleAs="paragraphSmall" className={styles.link} noMargins>
        {variantCopy}
      </Typography>
    </Link>
  );
};

MultibuyNudge.propTypes = {
  multibuyPromotionPath: PropTypes.string.isRequired,
  variantCopy: PropTypes.string.isRequired,
};

export default MultibuyNudge;
