import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@johnlewispartnership/wtr-ingredients/core/IconButton';
import { Bin } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './DeleteFromList.scss';

const DeleteFromList = ({ removeFromList }) => (
  <IconButton
    title="Delete from the list"
    data-testid="list-delete-button"
    data-actiontype="remove"
    data-origincomponent="ListsDelete"
    onClick={removeFromList}
    className={styles.deleteButton}
  >
    <Bin size="medium" />
    <span className="sr-only">Delete from the list</span>
  </IconButton>
);
DeleteFromList.defaultProps = {
  removeFromList: () => {},
};

DeleteFromList.propTypes = {
  removeFromList: PropTypes.func,
};

DeleteFromList.displayName = 'Favourite';

export default DeleteFromList;
