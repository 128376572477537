import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import ProductSize from 'components/Product/Size';
import { dataAttributesGenerator } from 'utils/data-attribute-generator';
import { getPDPUrl } from 'utils/product';

import styles from './index.scss';

const ProductPodHeader = ({ children, fullSize, name, productId, onClick, withoutSpacing }) => {
  if (!productId) return null;

  return (
    <header
      data-testid="product-pod-header"
      className={classNames(styles.podHeader, {
        [styles.fullSize]: fullSize,
        [styles.withoutSpacing]: withoutSpacing,
      })}
    >
      <TextLink
        underline="hover"
        href={getPDPUrl(productId, name)}
        {...dataAttributesGenerator({
          actiontype: 'redirect',
          originComponent: 'ProductPod',
          shortDescription: `view product details for ${name}`,
        })}
        onClick={onClick}
        className={styles.nameLink}
      >
        <h2 className={classNames(styles.nameWrapper)} data-testid="product-pod-name">
          <span className={classNames(styles.name, styles.ellipses)} title={name}>
            {name}
          </span>
        </h2>
        <ProductSize additionalClasses={styles.sizeMessage} productId={productId} />
      </TextLink>
      {children}
    </header>
  );
};

ProductPodHeader.displayName = 'ProductPodHeader';

ProductPodHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  fullSize: PropTypes.bool,
  name: PropTypes.string,
  productId: PropTypes.string,
  onClick: PropTypes.func,
  withoutSpacing: PropTypes.bool,
};

ProductPodHeader.defaultProps = {
  children: undefined,
  fullSize: false,
  name: undefined,
  productId: undefined,
  withoutSpacing: false,
  onClick: () => {},
};

export default memo(ProductPodHeader);
